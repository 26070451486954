<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card title="Oluştur">
        <item-form-map-scores />
      </b-card>
      <b-row>
        <b-col class="text-right">
          <b-button
            :disabled="dataItem.submitStatus"
            variant="primary"
            @click="submitData"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>
<script>
import {
  BCard, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import ItemFormMapScores from '@/views/MapScores/ItemForm.vue'

export default {
  name: 'Add',
  components: {
    ItemFormMapScores,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BButton,
  },
  computed: {
    dataItem() {
      return this.$store.getters['mapScores/dataItem']
    },
    saveStatus() {
      return this.$store.getters['mapScores/dataSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.$router.push('/map-scores')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.$store.commit('mapScores/SET_RESET_DATA_ITEM')
    localize('tr')
  },
  methods: {
    submitData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.dataItem.submitStatus = true
          this.$store.dispatch('mapScores/saveData', this.dataItem)
        }
      })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
  },
}
</script>
